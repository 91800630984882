<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="6" cols="12" class="py-0">
                  <v-btn
                    depressed
                    small
                    class="mx-2"
                    color="deep-purple lighten-2"
                    @click="$router.go(-1)"
                    dark
                    mdi-keyboard-backspace
                    ><v-icon left> mdi-keyboard-backspace </v-icon> Back</v-btn
                  >
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                </v-col>
                <v-col sm="6" cols="12" class="py-0">
                  <download-excel
                    :data="listStudent"
                    :fields="exportFields"
                    name="annual_scores.xls"
                  >
                    <v-btn
                      class="m-2 float-right"
                      color="primary"
                      @click="filterStudent()"
                      ><h5>
                        Export Annual Score
                        <i class="fa fa-file-excel fa-lg"></i>
                      </h5>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <div style="margin-top: 20px"></div>

              <v-row justify="center">
                <!-- <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider> -->

                <v-col sm="12" cols="12">
                  <v-row>
                    <v-col sm="6" cols="12" md class="text-center">
                      <div class="text-h5">Result Of the Exam</div>
                      <div class="text-h6">
                        {{ academic_year[0] }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" cols="12">
                      <div class="float-left text-h7">
                        Class: {{ major[0] }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-card>
                    <template>
                      <v-data-table
                        :headers="listHeader"
                        :items="listStudent"
                        class="elevation-1"
                        hide-default-header
                        hide-default-footer
                        :sort-by.sync="sortBy"
                        disable-pagination
                        :sort-desc.sync="sortDesc"
                      >
                        <template v-slot:header="{ props }">
                          <thead style="white-space: nowrap">
                            <tr>
                              <th
                                :colspan="listHeader.length + 2"
                                style="text-align: center"
                              >
                                {{ major_gen[0] }}
                              </th>
                            </tr>

                            <tr>
                              <th class="text-center">No</th>
                              <th
                                :class="[
                                  'column sortable',
                                  sortDesc ? 'desc' : 'asc',
                                  header.value === sortBy ? 'active' : '',
                                ]"
                                v-for="header in props.headers"
                                :key="header.text"
                                class="text-center"
                              >
                                <span
                                  v-if="header.text == 'Student ID'"
                                  style="text-align: center"
                                >
                                  <th rowspan="2">{{ header.text }}</th>
                                </span>
                                <span
                                  v-else-if="header.text == 'Student Name'"
                                  style="text-align: center"
                                  >{{ header.text }}</span
                                >
                                <span
                                  v-else-if="header.text == 'Total Score'"
                                  style="text-align: center"
                                  >{{ header.text }}
                                </span>
                                <span
                                  v-else-if="header.text == 'Rank'"
                                  style="text-align: center"
                                  >{{ header.text }}
                                </span>
                                <span
                                  v-else-if="header.text == 'GPA'"
                                  style="text-align: center"
                                  >{{ header.text }}
                                </span>
                                <span
                                  v-else-if="header.text == 'MARK'"
                                  style="text-align: center"
                                  >{{ header.text }}
                                </span>
                                <span v-else style="text-align: center"
                                  >{{ header.text }}<br />

                                  (Credit:{{ header.credit }})</span
                                >
                                <v-icon
                                  small
                                  @click="changeSort(header.value)"
                                  v-if="sortDesc"
                                  color="red"
                                  >arrow_upward</v-icon
                                >
                                <v-icon
                                  small
                                  v-else
                                  color="red"
                                  @click="changeSort(header.value)"
                                  >arrow_downward</v-icon
                                >
                              </th>
                              <th class="text-center">
                                {{ $t("Action") }}
                              </th>
                            </tr>
                          </thead>
                        </template>

                        <template v-slot:body="props">
                          <tbody style="white-space: nowrap">
                            <tr
                              v-for="(index, i) in props.items"
                              :key="i"
                              :class="
                                approvedStatus(index.student_id) != null
                                  ? 'deep-purple lighten-5'
                                  : 'white'
                              "
                              @click="
                                $router.push({
                                  name: 'Transcript',
                                  params: {
                                    student_id: index.student_id,
                                    annual_id: annual_id,
                                  },
                                })
                              "
                            >
                              <td
                                class="text-center font-weight-black"
                                style="
                                  height: 35px;
                                  border: 1pt solid rgb(104, 102, 102) !important;
                                "
                              >
                                {{ 1 + i++ }}
                              </td>
                              <td
                                v-for="(header, indx) in listHeader"
                                :key="indx"
                                style="
                                  height: 35px;
                                  border: 1pt solid rgb(104, 102, 102) !important;
                                "
                                class="text-center font-weight-black"
                              >
                                <span>
                                  <span v-if="indx == 0" class="float-left">
                                    {{ index[header.value] }}</span
                                  >
                                  <span
                                    v-else-if="indx == 1"
                                    class="float-left"
                                  >
                                    {{ index[header.value] }}</span
                                  >
                                  <span v-else class="text-center">
                                    {{ index[header.value] }}</span
                                  >
                                </span>
                              </td>
                              <td
                                class="text-center"
                                style="
                                  border: 1pt solid rgb(104, 102, 102) !important;
                                "
                              >
                                <v-btn
                                  @click="
                                    $router.push({
                                      name: 'Transcript',
                                      params: {
                                        student_id: index.student_id,
                                        annual_id: annual_id,
                                      },
                                    })
                                  "
                                  max-height="30"
                                  class="rounded-pill"
                                  x-small
                                  color="deep-purple lighten-1"
                                  dark
                                >
                                  <span class="text-capitalize">
                                    VIEW TRANS.
                                  </span>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </template>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  generateAnnualScore,
  getApproveStatus,
} from "@schoolbase/web-client-lib";
import store from "@/store";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  name: "app",
  data() {
    return {
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      id: "",
      sortBy: "",
      sortDesc: true,
      isLoading: false,
      myLoading: false,
      session_ids: [],
      sess: [],
      listSessionScore: [],
      academic_year: "",
      annual_id: "",
      generate: "",
      major: "",
      major_gen: "",
      listHeader: [],
      listStudent: [],
      approveStatus: null,
      token: store.getters.getToken,
      exportFields: {},
    };
  },
  components: {
    Loading,
  },
  computed: {
    // isDisabled: function() {
    //   return !this.approveBool;
    // }
  },
  methods: {
    onReturn(item) {
      window.console.log(item);
    },
    filterStudent() {
      if (this.listStudent && this.listHeader) {
        for (let header of this.listHeader) {
          if (this.listStudent[0].hasOwnProperty(header.value)) {
            this.exportFields[header.text] = header.value;
          }
        }
      }
    },
    async getStatusApproved() {
      try {
        let response = await getApproveStatus(this.annual_id, this.token);
        this.approveStatus = response.payload;
        if (response.token) {
          this.$store.commit("LOGGED_TOKEN", response.token);
        }
      } catch (e) {
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
      }
    },

    approvedStatus(stu_id) {
      for (let item of this.approveStatus) {
        let data = item;
        if (data.student_id == stu_id) {
          if (data.approval >= 2) {
            return "Approved";
          }
        }
      }
    },

    async generateAnnualScore() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await generateAnnualScore(
          JSON.parse(this.$route.params.session_ids)
        );

        this.listHeader = APIResponse.payload.headers;

        this.listStudent = APIResponse.payload.students;
        this.academic_year = APIResponse.payload.session_details.map(
          (item) => item.academic_year
        );
        this.major = APIResponse.payload.session_details.map(
          (item) => item.major
        );
        this.major_gen = APIResponse.payload.session_details.map(
          (item) => item.major_gen
        );
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
      this.isLoading = false;
      this.myLoading = false;
    },
    async onSaveClose() {
      try {
        await generateAnnualScore(this.session_ids);
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },

    changeSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.descending = false;
      }
    },
  },
  async mounted() {
    this.generateAnnualScore();
    this.annual_id = this.$route.params.annual_id;
    this.getStatusApproved();
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: "Krasar-Bold", serif;
  font-size: 14px !important;
  color: black !important;
  border: 1pt solid rgb(104, 102, 102) !important;
}
</style>
